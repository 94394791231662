<template>
  <div class="header-wrapper">
    <div style="background: #fff;" class="w100">
      <div class="header-box">
        <div class="flex align-center">
          <div class="text-30 c_3291F8">广运职聘</div>
          <div class="flex align-center c_101010" style="margin-left: 30px">
            <div class="text-18 h-li cur-p" :class="{ c_3291F8: index == selIndex }"
              @click="navClcik(item.value, index)" v-for="(item, index) in navlist" :key="index">
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="flex align-center">
          <!-- <div class="text-18">0755-44584646</div> -->
          <div></div>
          <el-dropdown>
            <span style="cursor: pointer">{{ userInfo.name }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="logOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div style="width: 1200px; height: 200px; margin: 20px auto 0 ;" v-if="selIndex < 3">
      <img src="@/assets/icon_banner.jpg" style="width: 1200px; height: 200px;" alt="" srcset="">
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, watch, computed } from "vue";
import router from "@/router/index";
import { useStore } from 'vuex'
export default defineComponent({
  name: "",
  setup: (props, { emit }) => {
    const store = useStore();
    const state = reactive({
      selIndex: 0,
      navlist: [
        {
          label: "首页",
          value: "/home",
          name: 'Home'
        },
        {
          label: "找人",
          value: "/look/index",
          name: 'look'
        },
        {
          label: "职位",
          value: "/position/index",
          name: 'position'
        },
        {
          label: "消息",
          value: "/news/index",
          name: 'news'
        },
        {
          label: "账号中心",
          value: "/accountCenter/index",
          name: 'accountCenter'
        },
        {
          label: "数据统计",
          value: "/statistics/index",
          name: 'statistics'
        },
      ],
      userInfo: computed(() => store.state.user.userInfo)
    });
    var routetFIndex = state.navlist.findIndex(item => item.name == router.currentRoute.value.name);
    if (router.currentRoute.value.name == 'releasePosition' || router.currentRoute.value.name == 'jobDetails') {
      routetFIndex = 2;
    }
    console.log('router.currentRoute.value', router.currentRoute.value)
    if (router.currentRoute.value.name == 'resume') {
      routetFIndex = 1;
    }


    state.selIndex = routetFIndex >= 0 ? routetFIndex : state.selIndex;

    emit('navIndex', state.selIndex)
    const logOut = () => {
      window.localStorage.clear();
      router.push({ path: "/login" });
    };
    const navClcik = (pathurl: string, pathIndex: number): void => {
      console.log(pathurl);
      state.selIndex = pathIndex;

      router.push({ path: pathurl });
    };
    watch(() => state.selIndex, (newValue, oldValue) => {
      console.log('watch 已触发', newValue)
      emit('navIndex', newValue)
      }
    )
    watch(() => router.currentRoute.value.name, (newValue, oldValue) => {
      console.log(11111111111111, newValue)
      if (newValue == 'releasePosition' || newValue == 'jobDetails') {
        state.selIndex = 2;
      } else if (newValue == 'resume') {
        state.selIndex = 1;
      } else {
        let hasIndex = state.navlist.findIndex((item: any) => item.name == newValue);
        if (hasIndex >= 0) {
          state.selIndex = hasIndex;
        }
      }
    }, { immediate: true })
    return {
      logOut,
      navClcik,
      ...toRefs(state),
    };
  },
});
</script>
<style scoped>
.header-wrapper {
  font-size: 30px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;

  /* box-shadow: 24px 4px 29px #ccc; */
}

.header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 80px;
  box-sizing: border-box;
  margin: 0 auto;
}

.h-li {
  margin-right: 20px;
}

.el-icon-s-fold,
.el-icon-s-unfold {
  cursor: pointer;

  padding: 15px;
}
</style>